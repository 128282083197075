import React from "react";
import data from 'data.json';
import customer from 'customer.json';
import { getDayOfWeek, getTimeFormat, formatPhoneNumber } from 'helpers/functions'


import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";

import {
  Container,
  Row,
  Col,
} from "reactstrap";

const MapWrapper = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      defaultZoom={18}
      defaultCenter={{ lat: Number(customer.lat), lng: Number(customer.lng) }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.park", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.sports_complex",
            stylers: [{ visibility: "on" }]
          },
          { featureType: "poi.medical", stylers: [{ visibility: "on" }] },
          {
            featureType: "poi.business",
            stylers: [{ visibility: "simplified" }]
          }
        ]
      }}
    >
      <Marker position={{ lat: Number(customer.lat), lng: Number(customer.lng) }}>
        {/* <InfoBox
          defaultPosition={{ lat: customer.lat, lng: customer.lng }}
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
        >
          <div style={{ backgroundColor: `none`, opacity: 1.00, padding: `0px` }}>
            <div style={{ fontSize: '2vh', color: '#f96332', fontWeight: '600' }}>
              {data.landing.name}
            </div>
          </div>
        </InfoBox> */}
      </Marker>
    </GoogleMap >
  ))
);


function ContactUs() {
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div id='contact' className="wrapper" >
        <div className="main">
          <div className="contact-content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="6" xs='12' style={{ paddingRight: '0.6vh' }}>
                  <h2 className="title" >Business Hours</h2>
                  <div className="info info-horizontal ml-0">
                    <div className="icon" style={{ color: data.color.theme }}>
                      <i class="far fa-clock"></i>
                    </div>
                    <div className="description" style={{ marginRight: '0' }}>
                      <h5 className="info-title">
                        <table>
                          <tbody>
                            {JSON.parse(customer.hours).map((i, index) =>
                              <tr>
                                <td>{getDayOfWeek(index)}</td>
                                <td>{(i[0] === '' || i[0] == 0) ? 'Closed' : `${getTimeFormat(i[0])}-${getTimeFormat(i[1])}`} </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5 pb-0">
                    <div className="icon" style={{ color: data.color.theme }}>
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h5 className="info-title">{customer.address}</h5>
                    </div>
                  </div>
                  <div className="info info-horizontal ">
                    <div className="icon" style={{ color: data.color.theme }}>
                      <i class="fas fa-phone"></i>
                    </div>
                    <div className="description">
                      <h5 className="info-title">{formatPhoneNumber(customer.contactNumber)}</h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="big-map" id="contactUs2Map">
          <MapWrapper
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAb_ZjLtVTvwlhdJ2xk4Kajd_-dq8p9VhE&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    </>
  );
}

export default ContactUs;
