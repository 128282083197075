import React from "react";
import data from 'data.json';

import {
  Card,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";


function AboutUs() {
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="main" style={{ minHeight: '90vh' }}>
        <Container>
          <div className="section" style={{ paddingTop: '3vh' }}>
            <h2 className="title text-center" style={{ color: data.color.theme }}>
              <i class="fas fa-utensils" style={{ margin: '1vh' }}></i>
              Our Specialty
              <i class="fas fa-utensils" style={{ margin: '1vh' }}></i>
            </h2>
            <div className="separator-line bg-danger"></div>
            <br></br>
            <Row>
              <Col md="4">
                <CardTitle tag="h3" className='text-center'>
                  {data.specialty.specialty1} <i class="far fa-thumbs-up" style={{ color: '#2ca8ff' }} />
                </CardTitle>
                <Card className="card-plain card-blog">
                  <div className="card-image">
                    <img
                      alt="appetizer"
                      className="img rounded img-raised"
                      src={data.specialty.image1}
                    ></img>
                  </div>
                </Card>
                <Card className="card-plain card-blog">
                  <div className="card-image">
                    <img
                      alt="appetizer"
                      className="img rounded img-raised"
                      src={data.specialty.image2}
                    ></img>
                  </div>
                </Card>
              </Col>

              <Col md="4">
                <CardTitle tag="h3" className='text-center'>
                  {data.specialty.specialty2} <i class="far fa-smile" style={{ color: '#e69a0b' }} />
                </CardTitle>
                <Card className="card-plain card-blog">
                  <div className="card-image">
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src={data.specialty.image3}
                    ></img>
                  </div>
                </Card>
                <Card className="card-plain card-blog">
                  <div className="card-image">
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src={data.specialty.image4}
                    ></img>
                  </div>
                </Card>
              </Col>

              <Col md="4">
                <CardTitle tag="h3" className='text-center'>
                  {data.specialty.specialty3} <i class="fas fa-heart" style={{ color: 'red' }}></i>
                </CardTitle>
                <Card className="card-plain card-blog">
                  <div className="card-image">
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src={data.specialty.image5}
                    ></img>
                  </div>
                </Card>
                <Card className="card-plain card-blog">
                  <div className="card-image">
                    <img
                      alt="..."
                      className="img rounded img-raised"
                      src={data.specialty.image6}
                    ></img>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
