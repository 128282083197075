import React from "react";
import data from 'data.json';
import customer from 'customer.json';

import { Container, Row, Col, Button } from "reactstrap";


function Features() {
  return (
    <>
      <div className="cd-section" id="order" style={{ backgroundColor: data.color.orderbackground, minHeight: '75vh' }}>
        <Container fluid>
          <Row style={{ paddingTop: '9vh' }}>
            <Col className="px-0" md="6">
              <Col sm="12">
                <div className="info info-horizontal" >
                  <div className="card-image">
                    <img
                      alt="..."
                      className="rounded img-raised"
                      src={data.order.image1}
                    ></img>
                  </div>
                </div>
                <div style={{ paddingTop: '2vh', paddingLeft: '5vh', paddingRight: '4vh', paddingBottom: '1vh' }}>
                  <h5 className='text-left' style={{ color: data.color.about, fontWeight: '600' }}>{customer.about}</h5>
                </div>
                <div className="info">
                  <i><h3 className="info-title" style={{ color: data.color.orderword, fontWeight: '600' }} >Start Your Order Now</h3></i>
                </div>
                <div className="info info-horizontal">
                  <div className="buttons text-center">
                    <Button
                      size='lg'
                      href={customer.website}
                      style={{ backgroundColor: data.color.buttonbackground, boxShadow: data.color.orderbuttonshadow }}
                    >
                      <h5 style={{ fontWeight: 'bold', margin: '0', color: data.color.buttonword }}> <i class="far fa-clock" />  Order Online</h5>
                    </Button>
                  </div>
                </div>
              </Col>
            </Col>
            <Col md="6">
              <div className="image-container">
                <div className="card-image">
                  <img
                    alt="..."
                    className="rounded img-raised"
                    src={data.order.image2}
                  ></img>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Features;
