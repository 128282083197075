import React from "react";
import data from 'data.json';
import customer from 'customer.json';

import {
  Button,
  Navbar,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";


const items = [
  {
    src: `url(${data.landing.image1})`,
    content: (
      <Row style={{ paddingTop: '28vh' }}>
        <Col className="ml-auto mr-auto text-center" md="12" xs='12'>
          <i><h3 style={{ margin: '0' }}>Welcome To</h3></i>
          <h1 className="title"
            style={{ fontWeight: 'dark', fontFamily: 'Orbitron, sans-serif', fontSize: '8vh', paddingBottom: '3vh', paddingTop: '1.5vh' }}
          >{customer.name}
          </h1>
          <div className="buttons">
            <Button
              href={customer.website}
              size="lg"
              style={{ backgroundColor: data.color.theme, boxShadow: data.color.landingbuttonshadow }}
            >
              <h5 style={{ fontWeight: 'bold', margin: '0' }}>Order Online</h5>
            </Button>
          </div>
          <h4 className="description"
            style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 'bold', color: data.color.landingword, marginTop: '2vh' }}
          >
            Where Happiness Begins
          </h4>
        </Col>
      </Row>
    ),
    altText: "",
    caption: "",
  },
  {
    src: `url(${data.landing.image2})`,
    content: (
      <Row style={{ paddingTop: '30vh' }}>
        <Col className="ml-auto mr-auto text-center" md="12" xs='12'>
          <i><h3 style={{ margin: '0' }}>Welcome To</h3></i>
          <h1 className="title"
            style={{ fontWeight: 'dark', fontFamily: 'Orbitron, sans-serif', fontSize: '8vh', paddingBottom: '3vh', paddingTop: '1.5vh' }}
          >{customer.name}
          </h1>
          <div className="buttons">
            <Button
              href={customer.website}
              size="lg"
              style={{ backgroundColor: data.color.theme, boxShadow: data.color.landingbuttonshadow }}
            >
              <h5 style={{ fontWeight: 'bold', margin: '0' }}>Order Online</h5>
            </Button>
          </div>
          <h4 className="description"
            style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 'bold', color: data.color.landingword, marginTop: '2vh' }}
          >
            Where Love Begins
          </h4>
        </Col>
      </Row>
    ),
    altText: "",
    caption: "",
  },
  {
    src: `url(${data.landing.image3})`,
    content: (
      <Row style={{ paddingTop: '25vh' }}>
        <Col className="ml-auto mr-auto text-center" md="12" xs='12'>
          <i><h3 style={{ margin: '0' }}>Welcome To</h3></i>
          <h1 className="title"
            style={{ fontWeight: 'dark', fontFamily: 'Orbitron, sans-serif', fontSize: '8vh', paddingBottom: '3vh', paddingTop: '1.5vh' }}
          >{customer.name}
          </h1>
          <div className="buttons">
            <Button
              href={customer.website}
              size="lg"
              style={{ backgroundColor: data.color.theme, boxShadow: data.color.landingbuttonshadow }}
            >
              <h5 style={{ fontWeight: 'bold', margin: '0' }}>Order Online</h5>
            </Button>
          </div>
          <h4 className="description"
            style={{ fontFamily: 'Dancing Script, cursive', fontWeight: 'bold', color: data.color.landingword, marginTop: '2vh' }}
          >
            Tasty Food, Best Food
          </h4>
        </Col>
      </Row>
    ),
    altText: "",
    caption: "",
  },
];

function Headers() {
  // navbar collapses states and functions
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  const [navbarOpen2, setNavbarOpen2] = React.useState(false);
  const [navbarOpen3, setNavbarOpen3] = React.useState(false);
  // header 3 carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <div id='home'>
      {navbarOpen1 || navbarOpen2 || navbarOpen3 ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setNavbarOpen1(false);
            setNavbarOpen2(false);
            setNavbarOpen3(false);
          }}
        />
      ) : null}
      <div className="cd-section" id="headers">
        <div className="header-3">
          <Navbar
            className="navbar-transparent bg-primary navbar-absolute"
            expand="lg"
          >
            <Container>
              <div className="navbar-translate">
                <button
                  aria-expanded={navbarOpen3}
                  className="navbar-toggler"
                  data-toggle="collapse"
                  type="button"
                  onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    setNavbarOpen1(!navbarOpen3);
                  }}
                >
                  <span className="navbar-toggler-bar bar1"></span>
                  <span className="navbar-toggler-bar bar2"></span>
                  <span className="navbar-toggler-bar bar3"></span>
                </button>
              </div>
              <ScrollTransparentNavbar />
            </Container>
          </Navbar>
          <Carousel activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={items}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {items.map((item) => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div className="page-header header-filter">
                    <div
                      className="page-header-image"
                      style={{
                        backgroundImage: item.src,
                      }}
                    ></div>
                    <div className="content">
                      <Container className="text-left">
                        {item.content}
                      </Container>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#pablo"
              onClick={(e) => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Headers;
