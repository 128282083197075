import React from 'react';
import LandingPage from 'views/LandingPage';
import OurSpecialtyPage from 'views/OurSpecialtyPage';
import OrderOnlinePage from 'views/OrderOnlinePage';
import ContactPage from 'views/ContactPage';
import Footer from 'views/Footer';


export default function App() {
  return (
    <>
      <LandingPage />
      <OurSpecialtyPage />
      <OrderOnlinePage />
      <ContactPage />
      <Footer />
    </>
  )
}