import React from "react";
import data from 'data.json';
import customer from 'customer.json';
import {
  Collapse,
  Navbar,
  NavItem,
  Nav,
  Container,
  NavLink,
} from "reactstrap";

function ScrollTransparentNavbar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? ""
      : " navbar-transparent"
  );
  const [buyButtonColor, setBuyButtonColor] = React.useState(
    (document.documentElement.scrollTop > 499 || document.body.scrollTop) > 499
      ? "info"
      : "neutral"
  );
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        setBuyButtonColor("info");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor(" navbar-transparent");
        setBuyButtonColor("neutral");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top" + navbarColor} expand="lg" style={{ backgroundColor: data.color.theme }}>
        <Container>
          <div className="navbar-translate">
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" style={{ fontWeight: 'bold' }}>
              <Nav navbar>
                <NavItem >
                  <NavLink href="#home" >
                    <h5 style={{ fontWeight: 'bold', marginBottom: '0' }}>Home</h5>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={customer.website}>
                    <h5 style={{ fontWeight: 'bold', marginBottom: '0' }}>Order Online</h5>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="#contact">
                    <h5 style={{ fontWeight: 'bold', margin: '0' }}>Contact Us</h5>
                  </NavLink>
                </NavItem>
              </Nav>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ScrollTransparentNavbar;
